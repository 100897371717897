import { ElMessage, ElMessageBox } from 'element-plus';
import { cache } from './funs';

// 成功提示信息
export function succesMsg(msgInfo) {
  ElMessage({
    type: 'success',
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: msgInfo,
  })
}

// 警告提示信息
export function warnMsg(msgInfo) {
  ElMessage({
    type: 'warning',
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: msgInfo,
  })
}

// 错误提示信息
export function errorMsg(msgInfo) {
  ElMessage({
    type: 'error',
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: msgInfo,
  })
}

// 一般信息提示信息
export function infoMsg(msgInfo) {
  ElMessage({
    type: 'info',
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: msgInfo,
  })
}

// 确定一个确定按钮alertBox
export function alertBox(msg, btnName='', type) {
  let language = (cache('language') == '' || cache('language') == 'cn') ? 'cn' : 'en';
  let confirmName = btnName == '' ? '确定' : btnName;
  if(language == 'en'){
    confirmName = btnName == '' ? 'confirm' : btnName;
  }  
  let tipTitle = language == 'cn'? '提示' : 'Prompt';
  return ElMessageBox.alert(msg, tipTitle, {
    type: type,
    confirmButtonText: confirmName,
    dangerouslyUseHTMLString: true,
    confirmButtonClass: 'alertConfirmBtn'
  });
}

// 确定取消;是否按钮弹出框
export function confirmBox(msg, btnName, type) {
  let language = (cache('language') == '' || cache('language') == 'cn') ? 'cn' : 'en';
  let confirmName = btnName == '确定' ? '确定' : '是';
  let cancelsName = btnName == '取消' ? '取消' : '否';
  if(language == 'en'){
    confirmName = btnName == 'confirm' ? 'confirm' : 'yes';
    cancelsName = btnName == 'cancel' ? 'cancel' : 'no';
  }
  let tipTitle = language == 'cn'? '提示' : 'Prompt';
  return ElMessageBox.confirm(msg, tipTitle, {
    type: type,
    confirmButtonText: confirmName,
    cancelButtonText: cancelsName,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    dangerouslyUseHTMLString: true,
    customClass: 'myBox',
    confirmButtonClass: 'myBoxConfirmClass',
    cancelButtonClass: 'myBoxCancelClass'
  })
}