// import ContentHeader from './contentHeader/Index'
import SvgIcon from './icons/SvgIcon'
import PageLoading from './Loading/PageLoading'
// import Storage from './upload/Storage'
// import GetPoint from './map/tencent/GetPoint'
export default {
    install(Vue){
        // 注册更多全局组件...
        // svg图标
        Vue.component('svg-icon', SvgIcon)
        // 页面加载中
        // eslint-disable-next-line vue/multi-word-component-names
        Vue.component('page-loading', PageLoading)
        // // 头部标题
        // Vue.component('ContentHeader',ContentHeader)
        // // 文件管理
        // Vue.component('Storage',Storage)
        // // 地图拾取
        // Vue.component('GetPoint',GetPoint)
    }
}