import axios from 'axios';
import { notice, clearLogin, cache } from "@/config/default/funs";
import { BASE_URL } from "../../../public/config";
import router from "@/router"; //加载路由，不知道为何后台管理henbio/ManagePage框架不能使用该方式，报了Uncaught TypeError: iter is undefined错误

// 创建 axios 实例
const request = axios.create({
    // 服务端api地址
    baseURL: BASE_URL ? BASE_URL : '',
    // 请求超时时间，10分钟, 如果上传大文件需要更长
    timeout: 600 * 1000
})
// 接口请求拦截
request.interceptors.request.use(config => {
    // console.log(config)
    return config
})

// 接口响应拦截
request.interceptors.response.use((response) => {
    let language = cache('language');
    const result = response.data
    if(typeof(result) == 'string'){
        if(language == '' | language == 'cn'){
            notice('错误', '数据格式错误', 'error')
        }else{
            notice('error', 'Data format error', 'error')
        }
        return Promise.reject(result)
    }
    // result.status [ 200正常 500有错误 401未登录 403没有权限访问 404未找到资源]
    if (result.status === 403 || result.status === 404 || result.status === 500) {
        if(language == '' | language == 'cn'){
            notice('错误', result.message, 'error')
        }else{
            notice('error', result.message, 'error')
        }
        if(result.message == '工具不存在'){
            router.push({ //跳转到登录页面
                path: '/',
            })
        }else if(result.message == 'Tool does not exist'){
            router.push({ //跳转到登录页面
                path: '/en',
            })
        }
        return Promise.reject(result)
    }
    // 未登录
    if (result.status === 401) { 
        //当前页面是首页的不用跳转到登录页
        if(router.currentRoute._value.name != undefined && router.currentRoute._value.name != 'HomeView' && router.currentRoute._value.name != 'HomeViewEn'){
            // let path = (language == '' | language == 'cn') ? '/user/login' : '/en/user/login';
            let name = (language == '' | language == 'cn') ? 'UserLogin' : 'UserLoginEn';
            clearLogin()  
            router.push({ //跳转到登录页面
                name,
            })
        }
        // window.location.reload()
        return Promise.reject(result)
    }
    //未支付
    if(result.status === 402){
        return Promise.reject(result)
    }
    return result
}, (error) => {
    // 网络请求出错
    let errMsg = ''
    if(cache('language') == '' | cache('language') == 'cn'){
        if(axios.isCancel(error)){
            errMsg = ((error.response || {}).data || {}).message || '取消本次请求'
        }else{
            errMsg = ((error.response || {}).data || {}).message || '请求出现错误，请稍后再试'
            notice('错误', errMsg, 'error')
        }
    }else{
        if(axios.isCancel(error)){
            errMsg = ((error.response || {}).data || {}).message || 'Cancel the current request'
        }else{
            errMsg = ((error.response || {}).data || {}).message || 'An error occurred with the request. Please try again later'
            notice('error', errMsg, 'error')
        }
    }
    return Promise.reject(error)
})
export { request as http }