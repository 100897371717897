<template>
  <div class="footerOut">
    <div class="footerInner">
      <div class="left">
        <div class="inline">
          <svg-icon type="company" />
          <span>{{FOOT_COMPANY.NAME}}</span>
        </div>
        <div class="inline">
          <svg-icon type="address" />
          <span>{{FOOT_COMPANY.ADDRESS}}</span>
        </div>
        <div class="inline">
          <div>
            <svg-icon type="telephone" />
            <span>{{FOOT_COMPANY.TELEPHONE}}</span>
          </div>
          <div class="qq">
            <svg-icon type="qq" />
            <span>{{FOOT_COMPANY.QQ}}</span>
          </div>
        </div>
        <div class="inline">
          <svg-icon type="email" />
          <span>{{FOOT_COMPANY.EMAIL}}</span>
        </div>
      </div>
      <div class="center">
        <div class="one">云分析</div>
        <div class="two">
          <div>公司简介</div>
          <div>组织架构</div>
          <div>企业荣誉</div>
        </div>
        <div class="three">
          <div>通知公告</div>
          <div>公司要闻</div>
        </div>
      </div>
      <div class="right">
        <div>
          <img src="../assets/images/qrcode.png" />
          <div>翰佰尔公众号</div>
        </div>
      </div>      
    </div>
    <div class="bottom" v-html="footInfo"></div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { FOOT_COMPANY } from '../../public/config'

export default defineComponent({
  name: "FooterLayer",
  props: [""],
  setup() {
    let footInfo = ref();

    onMounted(()=>{
      const url = window.location.href;
      // 通过正则表达式解析出域名
      let domainReg = new RegExp("^https?://([^:/]+)", 'i');
      let matches = url.match(domainReg);
      let domain = matches && matches[1];
      if(domain){
        domain = domain.toLowerCase();
        if(domain.indexOf('cn') > -1){ //如果访问是cn结尾的域名
          footInfo.value = FOOT_COMPANY.COPYRIGHT_CN;
        }else if(domain.indexOf('com') > -1){ //如果访问是com结尾的域名
          footInfo.value = FOOT_COMPANY.COPYRIGHT_COM;
        }else{
          footInfo.value = FOOT_COMPANY.COPYRIGHT_COM;
        }
      }
    });

    return {
      FOOT_COMPANY,
      footInfo
    };
  }
});
</script>

<style scoped>
.footerOut{
  font-size: 0.95rem;
  width: 100%;
  background-color: #414141;
 
}
.footerInner {
  width: 1100px;
  margin: 0 auto;
  color: #ADADAD;
  padding: 20px 0 10px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.footerInner .left img{
  width:20px;
  height: 20px;
}
.footerInner .left .inline img, .footerInner .left .inline span{
  display: inline-block;
  vertical-align: middle;
}
.footerInner .left .inline span{
  margin-left: 5px;
  height: 30px;
}
.footerInner .center{
  display: flex;
  flex-direction: row;
  width: 430px;
  justify-content: space-between;
}
.footerInner .right img{
  width: 85px;
  height: 85px;
}
.footerInner .right div{
  text-align: center;
}
.bottom{
  width:1200px;
  margin: 0 auto;
  border-top: 1px solid #737373;
  padding:20px 0;
  color: #ADADAD;
  text-align: center;
}
.bottom >>> a{
  color: #ADADAD;
}
.bottom >>> a:hover{
  color: #fff;
}
.inline{
  display: flex;
  align-items: center;
}
.inline .qq{
  margin-left: 60px;
}
.svg-icon{
  width: 15px !important;
  height: 15px !important;
}
</style>