// const app = require('@/config/default/app')
const funs = require('@/config/default/funs')
const { http } = require('@/config/utils/http')
const { withLoading } = require('@/config/default/loading')
const { alertBox } = require('@/config/default/MsgBox')
// const { routeList } = require('@/config/default/route')
// const { directive } = require('@/config/default/directive')
// 统一集中导出
module.exports = {
    // app,
    ...funs,
    http,
    withLoading,
    alertBox
    // routeList,
    // directive
}