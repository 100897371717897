import { createStore } from 'vuex'
import { cache } from "@/config";
import { getUnreadMessageNums } from '@/api/user/Notice'

export default createStore({
  state: {
    //当前用户信息
    userInfo: {},
    //统计未读信息数
    unreadMessageNums: 0,
    //统计未读公告数
    unreadNoticeNums: 0,
    //统计未读站内数
    unreadInStationNums: 0,
    language: cache("language")
  },
  getters: { //外部访问store.getters.getUnreadMessageNums
    getUnreadMessageNums(state){
      return state.unreadMessageNums;
    },
    getUnreadNoticeNums(state){
      return state.unreadNoticeNums;
    },
    getUnreadInStationNums(state){
      return state.unreadInStationNums;
    }
  },
  mutations: { //同步
    /**
     * 更新登录信息
     * @param {*} state 
     * @param {*} userInfo 
     */
    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      let cacheData = {
        'Authorization-Token': userInfo['Authorization-Token'],
        'Avatar': userInfo['Avatar'],
        'UserName': userInfo['UserName']
      };
      cache("userInfo", cacheData);
      // cache("userInfo", userInfo)
    },
    countUnreadMessageNums(state){ //统计未读信息条数
      getUnreadMessageNums().then((result) => {
        if (result.status == 200) {
          state.unreadNoticeNums = result.data.unreadNoticeNums;
          state.unreadInStationNums = result.data.unreadInStationNums;
          state.unreadMessageNums = state.unreadNoticeNums + state.unreadInStationNums;
        }
      }).catch(() => {
        state.unreadNoticeNums = 0;
        state.unreadInStationNums = 0;
        state.unreadMessageNums = state.unreadNoticeNums + state.unreadInStationNums;
      });
    },
    setLang(state, lang='cn'){ //设置网站语言
      cache("language", lang);
      state.language = lang;
    },
    setPreviousPagePath(state, options){
      cache('previousPagePath', options);
    }
  },
  actions: { //异步
    // countUnreadNoticeNumsAsyn({ commit }) {
    //   setTimeout(() => {
    //     commit('countUnreadNoticeNums');
    //   }, 1000);
    // }
  },
  modules: {
  }
})
