<template>
  <div class="slideOut" id="lazyload" @mouseenter="showArrowFn" @mouseleave="hideArrowFn">
    <div class="leftArrow left" v-show="showArrow">
      <img src="@/assets/images/left.png" v-if="currentPage" @click="arrowClick('left')"/>
    </div>
    <div class="loadData" v-if="isLoad"></div>
    <el-carousel :interval="3000" arrow="never" ref="cardShow" v-else>
      <el-carousel-item v-for="(item, index) in slides" :key="index">
        <!-- <a :href="item.link" class="banner" :style="{backgroundImage:'url(' + item.img_src + ')',backgroundRepeat:'no-repeat',backgroundPosition:'center center',backgroundSize: 'cover'}" >
        </a> -->
        <!-- <img :src="item.imgPath" class="banner" :title="item.title" v-lazy="item.img_src" /> -->
        <a :href="item.link" class="banner" v-lazy:background-image="item.img_src" :key="item.id" :style="{backgroundRepeat:'no-repeat',backgroundPosition:'center center',backgroundSize: 'cover'}" >
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="rightArrow right" v-show="showArrow">
      <img src="@/assets/images/right.png" @click="arrowClick('right')" v-if="pageLength !== currentPage + 1" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import { getCurrentInstance } from 'vue'; 
import { list } from '@/api/public/Banners';
import { cache } from "@/config";

export default defineComponent({
  name: "SlidingBanner",
  props: [""],
  setup() {
    const state = reactive({
      slides: [],
      isLoad: true//用于初始加载页面数据时的数据缓冲
    });

    onMounted(async() => {
      try {
        let language = cache('language');
        const result = await list({ 'lang': language });
        if(result.status === 200){
          state.slides = result.data;
          state.isLoad = false;
        }
      } catch (error) {
        // console.log(error);
      }
    });    

    const {proxy} = getCurrentInstance();//得到主组件的实例
    
    let showArrow = ref(false);//是否显示左右箭头，false不显示
    const showArrowFn = () => {
      showArrow.value = true;
    }
    const hideArrowFn = () => {
      showArrow.value = false;
    }

    let currentPage = ref(1);
    let pageLength = state.slides.length;
    const arrowClick = (val) => {
      if (val === "right") {
        proxy.$refs.cardShow.next();
      } else {
        proxy.$refs.cardShow.prev();
      }
    };

    return {
      ...toRefs(state),
      // slides,
      currentPage,
      pageLength,
      arrowClick,
      showArrow,
      showArrowFn,
      hideArrowFn
    };
  },
});
</script>

<style lang="less" scope>
.slideOut {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin-top: 75px;
  position: relative;
}
.slideOut .loadData{
  background-repeat: no-repeat; 
  background-position: center center; 
  background-size: cover; 
  background-image: url('@/assets/images/loading.gif');
  height: 500px;
}
.banner{
  width:100%;
  height: 500px;
  overflow: hidden;
  display: inline-block;
}
.slideOut .leftArrow, .slideOut .rightArrow{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  z-index: 999;
  width:50px;
  height: 50px;
}
.left{
  left: 20px;
}
.right{
  right: 20px;
}
.slideOut .rightArrow img, .slideOut .leftArrow img{
  width: 100%;
}
.slideOut :deep(.el-carousel--horizontal){
  width:100%
}
.el-carousel__container {
  height: 500px !important;
}
.el-carousel {
  --el-carousel-indicator-width: 39px !important;
  --el-carousel-indicator-height: 4px !important;
}
.leftArrow img, .rightArrow img{
  cursor: pointer;
}
</style>