import { getUrlParams, cache, /*splicingParams,*/ } from "@/config";
import store from "@/store";
import { createRouter, /*createWebHashHistory,*/  createWebHistory } from 'vue-router';
import { APP_DESCRIPTION, APP_KEYWORDS } from '../../public/config';
import Home from '../views/HomeView.vue';
import { setRouterOption } from '@/config/default/funs';

// 404页面
const page404 = { 
  path: '/:pathMatch(.*)*', 
  name: '404', 
  component: () => import('../exception/404.vue') 
}

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home, //由于Home.vue页面有el-tooltip的my-tooltip样式，而且这个样式不能使用scoped局部样式，所以此处不能使用import()，否则Tools.vue会使用不了的问题。
    meta: { 
      title: '首页',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "About" */ '../views/AboutView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "News" */ '../views/NewsView.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUsView.vue')
  },
  {
    path: '/tools',
    name: 'ToolsView',
    component: () => import(/* webpackChunkName: "Tools" */ '../views/ToolsView.vue'),
    meta: { 
      title: '云分析',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/instructions',
    name: 'InstructionsView',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/InstructionsView.vue'),
    meta: { 
      title: '网站使用说明',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/tooldetail',
    name: 'ToolDetailView',
    component: () => import(/* webpackChunkName: "ToolDetail" */ '../views/ToolDetailView.vue'),
    meta: { 
      title: '分析工具',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/videos',
    name: 'VideosView',
    component: () => import(/* webpackChunkName: "Videos" */ '../views/VideosView.vue'),
    meta: { 
      title: '视频',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/videodetail',
    name: 'VideoDetailView',
    component: () => import(/* webpackChunkName: "VideoDetail" */ '../views/VideoDetailView.vue'),
    meta: { 
      title: '视频详情',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "Login" */ '../views/User/UserLogin.vue'),
    meta: { 
      title: '登录',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/user/regedit',
    name: 'UserRegedit',
    component: () => import(/* webpackChunkName: "Regedit" */ '../views/User/UserRegedit.vue'),
    meta: { 
      title: '账户注册',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/user/forgotpwd',
    name: 'ForgotPwd',
    component: () => import(/* webpackChunkName: "ForgotPwd" */ '../views/User/ForgotPwd.vue'),
    meta: { 
      title: '忘记密码',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/test',
    name: 'TestView',
    component: () => import(/* webpackChunkName: "TestView" */ '../views/TestView.vue'),
    meta: { 
      title: '测试',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/test1',
    name: 'TestView1',
    component: () => import(/* webpackChunkName: "TestView" */ '../views/TestView1.vue'),
    meta: { 
      title: '测试1',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/user',
    name: 'UserIndex',
    component: () => import(/* webpackChunkName: "User" */ '../views/User/UserIndex.vue'),
    children: [
      {
        path: 'overview',
        name: 'MyOverview',
        component: () => import(/* webpackChunkName: "Overview" */ '../views/User/MyOverview.vue'),
        meta: { //可以使用这种方式传值
          index: 1,
          title: '信息总览－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'info',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "UserInfo" */ '../views/User/UserInfo.vue'),
        meta: { //可以使用这种方式传值
          index: 2,
          title: '个人信息－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'projects',
        name: 'MyProjects',
        component: () => import(/* webpackChunkName: "MyProjects" */ '../views/User/MyProjects.vue'),
        meta: {
          index: 3,
          title: '我的项目－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'datas',
        name: 'MyDatas',
        component: () => import(/* webpackChunkName: "MyDatas" */ '../views/User/MyDatas.vue'),
        meta: {
          index: 4,
          title: '我的数据－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'collection',
        name: 'MyCollection',
        component: () => import(/* webpackChunkName: "MyCollection" */ '../views/User/MyCollection.vue'),
        meta: {
          index: 5,
          title: '我的收藏－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "Notice" */ '../views/User/MessageCenter.vue'),
        meta: {
          index: 6,
          title: '消息中心－用户中心',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      page404,
    ]
  },
  {
    path: '/en/',
    name: 'HomeViewEn',
    component: () => import(/* webpackChunkName: "HomeEn" */ '../views/En/HomeView.vue'),
    meta: { 
      title: 'Home',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/en/tools',
    name: 'ToolsViewEn',
    component: () => import(/* webpackChunkName: "ToolsEn" */ '../views/En/ToolsView.vue'),
    meta: { 
      title: 'Cloud analysis',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    },    
  },
  {
    path: '/en/instructions',
    name: 'InstructionsViewEn',
    component: () => import(/* webpackChunkName: "instructionsView" */ '../views/En/InstructionsView.vue'),
    meta: { 
      title: 'Instructions',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/en/tooldetail',
    name: 'ToolDetailViewEn',
    component: () => import(/* webpackChunkName: "ToolDetailEn" */ '../views/En/ToolDetailView.vue'),
    meta: { 
      title: 'Analysis tool',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/en/videos',
    name: 'VideosViewEn',
    component: () => import(/* webpackChunkName: "VideosEn" */ '../views/En/VideosView.vue'),
    meta: { 
      title: 'Videos',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/en/videodetail',
    name: 'VideoDetailViewEn',
    component: () => import(/* webpackChunkName: "VideoDetailEn" */ '../views/En/VideoDetailView.vue'),
    meta: { 
      title: 'Video details',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }    
  },
  {
    path: '/en/user/login',
    name: 'UserLoginEn',
    component: () => import(/* webpackChunkName: "LoginEn" */ '../views/En/User/UserLogin.vue'),
    meta: { 
      title: 'Log in',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    },    
  },
  {
    path: '/en/user/regedit',
    name: 'UserRegeditEn',
    component: () => import(/* webpackChunkName: "RegeditEn" */ '../views/En/User/UserRegedit.vue'),
    meta: { 
      title: 'Account Registration',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/en/user/forgotpwd',
    name: 'ForgotPwdEn',
    component: () => import(/* webpackChunkName: "ForgotPwdEn" */ '../views/En/User/ForgotPwd.vue'),
    meta: { 
      title: 'Forget your password',
      description: APP_DESCRIPTION,
      keywords: APP_KEYWORDS
    }
  },
  {
    path: '/en/user',
    name: 'UserIndexEn',
    component: () => import(/* webpackChunkName: "UserEn" */ '../views/En/User/UserIndex.vue'),
    children: [
      {
        path: 'overview',
        name: 'MyOverviewEn',
        component: () => import(/* webpackChunkName: "OverviewEn" */ '../views/En/User/MyOverview.vue'),
        meta: { //可以使用这种方式传值
          index: 1,
          title: 'Information Overview - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'info',
        name: 'UserInfoEn',
        component: () => import(/* webpackChunkName: "UserInfoEn" */ '../views/En/User/UserInfo.vue'),
        meta: { //可以使用这种方式传值
          index: 2,
          title: 'Personal Information - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'projects',
        name: 'MyProjectsEn',
        component: () => import(/* webpackChunkName: "MyProjectsEn" */ '../views/En/User/MyProjects.vue'),
        meta: {
          index: 3,
          title: 'My Project - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'datas',
        name: 'MyDatasEn',
        component: () => import(/* webpackChunkName: "MyDatas" */ '../views/En/User/MyDatas.vue'),
        meta: {
          index: 4,
          title: 'My Data - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'collection',
        name: 'MyCollectionEn',
        component: () => import(/* webpackChunkName: "MyCollection" */ '../views/En/User/MyCollection.vue'),
        meta: {
          index: 5,
          title: 'My Collection - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      {
        path: 'notice',
        name: 'NoticeEn',
        component: () => import(/* webpackChunkName: "Notice" */ '../views/En/User/MessageCenter.vue'),
        meta: {
          index: 6,
          title: 'Message Center - User Center',
          description: APP_DESCRIPTION,
          keywords: APP_KEYWORDS
        }
      },
      page404,
    ]
  },

  page404
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL), //使用带#地址栏方式访问（hash模式）
  history: createWebHistory(), //使用history模式访问
  routes
})

const handlePreviousPagePath = (routerName, roterQuery) => { //处理上次浏览页面的缓存路由信息，防止中英文切换后previousPagePath缓存的数据不正确的问题。
  if(routerName != 'UserLogin' && routerName != 'UserLoginEn'){
    store.commit('setPreviousPagePath', setRouterOption(routerName, roterQuery));
  }else{
    let language = cache('language');
    let previousRouterName = cache('previousPagePath')['name'] != undefined ? cache('previousPagePath')['name'] : '';
    let previousRouterQuery = cache('previousPagePath')['query'] != undefined ? cache('previousPagePath')['query'] : '';
    if(language == '' || language == 'cn'){
      if(previousRouterName != ''){
        store.commit('setPreviousPagePath',  setRouterOption(previousRouterName.replace('En', ''), previousRouterQuery));
      }
    }else{
      if(previousRouterName != '' && previousRouterName.indexOf('En') == -1){
        store.commit('setPreviousPagePath', setRouterOption(previousRouterName + 'En', previousRouterQuery));
      }
    }
  }
} 

router.beforeEach((to, /*from, next*/)=>{  
  /*********动态修改keywords和description*************/
  if(Object.keys(to.meta).length>0 && to.matched.length>0){
    let this_meta=to.matched[to.matched.length-1].meta
    if(this_meta.title)document.title = this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword = document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
    }else{
      meta_keyword.setAttribute('name','keywords')
      meta_keyword.setAttribute('content',this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description = document.createElement('meta');
    if(document.querySelector('meta[name="description"]')){
      document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
    }else{
      meta_description.setAttribute('name','description')
      meta_description.setAttribute('content',this_meta.description)
      head[0].appendChild(meta_description)
    }

    // let lang = store.state.language;
    // let urlParams = getUrlParams(window.location.href);    
    // let query = JSON.stringify(urlParams) == '{}' ? '' : urlParams;
    // if(lang == '' || lang == 'cn'){  
    //   if(to.name.indexOf('En') > -1){ //如果是中文状态下输入英文的地址，如：http://localhost:8080/#/en/tooldetail?id=318会强制跳转到http://localhost:8080/#/tooldetail?id=318
    //     let name = to.name.replace('En', '');
    //     const goto = router.resolve({
    //       name, //这里是跳转页面的name，要与路由设置保持一致
    //       query
    //     });
    //     window.open(goto.href, '_self');
    //   }
      
    //   if(to.name === 'ToolDetailView' && JSON.stringify(to.query) === '{}'){ //如果是工具详情页面并且传递参数为空（如：地址栏上无?id=318），则将地址栏获取到的参数补上
    //     const goto = router.resolve({
    //       name: to.name, //这里是跳转页面的name，要与路由设置保持一致
    //       query
    //     });
    //     window.open(goto.href, '_self');
    //   }
    // }else{
    //   if(to.name.indexOf('En') == -1){ //如果是英文状态下输入中文的地址，如：http://localhost:8080/#/tooldetail?id=318会强制跳转到http://localhost:8080/#/en/tooldetail?id=318
    //     let goto = router.resolve({
    //       path: '/en' + to.path,
    //       query
    //     });
    //     window.open(goto.href, '_self');
    //   }
    //   if(to.name === 'ToolDetailViewEn' && JSON.stringify(to.query) === '{}'){ //如果是工具详情页面并且传递参数为空（如：地址栏上无?id=318），则将地址栏获取到的参数补上
    //     const goto = router.resolve({
    //       name: to.name, //这里是跳转页面的name，要与路由设置保持一致
    //       query
    //     });
    //     window.open(goto.href, '_self');
    //   }
    // } 


    // let reg = /\w*En$/ig; //匹配以En结尾的字符串，如：ToolsViewEn
    let reg = new RegExp("\\w*En$", 'ig');
    let urlParams = getUrlParams(window.location.href);  
    let query = JSON.stringify(urlParams) == '{}' ? '' : urlParams;
    let matchResult = reg.test(to.name);
    if(matchResult){ //匹配到输入英文地址，如：http://localhost:8080/en/tools
      store.commit('setLang', 'en'); //将语言设置为英文
      /**
       * 如果是工具详情页面并且传递参数为空（如：地址栏上无?id=318），则将地址栏获取到的参数补上，
       * 此处防止工具详情页面中/英文切换的时候出现错误的问题。
       */
      if((to.name === 'ToolDetailViewEn' || to.name === 'VideoDetailViewEn') && query != '' && JSON.stringify(to.query) === '{}'){
        // const goto = router.resolve({
        //   name: to.name, //这里是跳转页面的name，要与路由设置保持一致
        //   query
        // });
        // window.open(goto.href, '_self');

        router.push({
          name: to.name,
          query: query
        });
      }else{
        handlePreviousPagePath(to.name, to.query);
      }
    }else{ //匹配到输入中文地址，如：http://localhost:8080/tools
      store.commit('setLang', 'cn'); //将语言设置为中文
      /**
       * 如果是工具详情页面并且传递参数为空（如：地址栏上无?id=318），则将地址栏获取到的参数补上，
       * 此处防止工具详情页面中/英文切换的时候出现错误的问题。
       */
      if((to.name === 'ToolDetailView' || to.name === 'VideoDetailView') && query != '' && JSON.stringify(to.query) === '{}'){
        // const goto = router.resolve({
        //   name: to.name, //这里是跳转页面的name，要与路由设置保持一致
        //   query
        // });
        // window.open(goto.href, '_self');

        router.push({
          name: to.name,
          query: query
        });
      }else{
        handlePreviousPagePath(to.name, to.query);
      }
    }    
  }
  /**********************************************/
  // next()
})

export default router
