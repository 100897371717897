import { axios } from "@/config";
const api = {
  regedit: '/user/regedit',
  login: '/user/login',
  smsLogin: '/user/smsLogin',
  logout: '/user/logout',
  userInfo: '/user/userInfo',
  editBaseInfo: '/user/editBaseInfo',
  changePwd: '/user/changePwd',
  changeEmail: '/user/changeEmail',
  changeMobile: '/user/changeMobile',
  forgotPwdStep1: '/user/forgotPwdStep1',
  forgotPwdStep2: '/user/forgotPwdStep2',
  forgotPwdStep3: '/user/forgotPwdStep3',
  getMySpaceInfo: '/user/getUserStorageSpace',
  sendCode: '/user/sendCode',
  sendActivationEmail: '/user/sendActivationEmail',
  getVerificationCode: '/user/getVerificationCode',
  sendEmailCode: '/user/sendEmailCode'
}

/**
 * 用户注册
 * @param {*} data
 */
export function regedit(data) {
  return axios({
    url: api.regedit,
    method: 'post',
    data
  })
}

/**
 * 用户登录，账号登录
 * @param {*} data
 */
export function login(data) {
  return axios({
    url: api.login,
    method: 'post',
    data
  })
}

/**
 * 用户登录，手机短信登录
 * @param {*} data
 */
export function smsLogin(data) {
  return axios({
    url: api.smsLogin,
    method: 'post',
    data
  })
}

/**
 * 退出登录
 * @param {*} data
 */
export function logout(data) {
  return axios({
    url: api.logout,
    method: 'post',
    data
  })
}

/**
 * 用户信息
 * @param {*} data
 */
export function userInfo(data) {
  return axios({
    url: api.userInfo,
    method: 'post',
    data
  })
}

/**
 * 修改用户基本信息
 * @param {*} data
 */
export function editBaseInfo(data) {
  return axios({
    url: api.editBaseInfo,
    method: 'post',
    data
  })
}

/**
 * 修改用户密码
 * @param {*} data
 */
export function changePwd(data) {
  return axios({
    url: api.changePwd,
    method: 'post',
    data
  })
}

/**
 * 修改用户邮箱
 * @param {*} data
 */
export function changeEmail(data) {
  return axios({
    url: api.changeEmail,
    method: 'post',
    data
  })
}

/**
 * 修改用户手机
 * @param {*} data
 */
export function changeMobile(data) {
  return axios({
    url: api.changeMobile,
    method: 'post',
    data
  })
}

/**
 * 找回密码第一步
 * @param {*} data 
 * @returns 
 */
export function forgotPwdStep1(data) {
  return axios({
    url: api.forgotPwdStep1,
    method: 'post',
    data
  })
}

/**
 * 找回密码第二步
 * @param {*} data 
 * @returns 
 */
 export function forgotPwdStep2(data) {
  return axios({
    url: api.forgotPwdStep2,
    method: 'post',
    data
  })
}

/**
 * 找回密码第三步
 * @param {*} data 
 * @returns 
 */
 export function forgotPwdStep3(data) {
  return axios({
    url: api.forgotPwdStep3,
    method: 'post',
    data
  })
}

/**
 * 获取我的空间信息
 * @param {*} data
 */
export function getMySpaceInfo(data) {
  return axios({
    url: api.getMySpaceInfo,
    method: 'post',
    data
  })
}

/**
 * 发送手机短信验证码
 * @param {*} data
 */
export function sendCode(data) {
  return axios({
    url: api.sendCode,
    method: 'post',
    data
  })
}

/**
 * 发送激活邮件
 * @param {*} data 
 * @returns 
 */
export function sendActivationEmail(data) {
  return axios({
    url: api.sendActivationEmail,
    method: 'post',
    data
  })
}

/**
 * 获取服务器验证码
 * @param {*} data 
 * @returns 
 */
export function getVerificationCode(data) {
  return axios({
    url: api.getVerificationCode,
    method: 'post',
    data
  })
}

/**
 * 发送电子邮件验证码
 * @param {*} data 
 * @returns 
 */
export function sendEmailCode(data) {
  return axios({
    url: api.sendEmailCode,
    method: 'post',
    data
  })
}