import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import globalComponents from '@/components/globalComponents' //新增全局组件配置
// const $ = require('jquery')
// window.$ = $
import VueLazyLoad from 'vue-lazyload'
// import axios from 'axios'
// import { getConfig } from '@/api/public/SiteConfig'
// import {EN_FOOT_COMPANY} from '../public/config'

let app = createApp(App)
app.use(store)
app.use(router)

app.use(ElementPlus, {
    locale: zhCn
})

app.use(globalComponents)
app.use(VueLazyLoad, {
    perLoad: 0.1, //预加载高度比例
    error: require('./assets/images/not_banner.png'), //报错需要的图片
    loading: require('./assets/images/loading.gif'), //替换需要的图片
    attempt: 3, //尝试次数
    // try: 2 // 这个是加载图片数量
})

// function getServerConfig() {
//     return new Promise((resolve, reject) => {
//         axios.get('http://apistest.henbio.cn/web/SiteConfig/getConfig').then(data => {
//             console.log("读取外部化配置文件>>>>>>>>")
//             console.log(data)  // 是否成功读取需要的配置项
//             // for (let key in data) {
//             //     // Vue.prototype["$" + key] = data[key];
//             // }
//             // console.log(Vue.prototype.$baseURL)  // 验证是否已经把属性挂载在了Vue的原型上
//             resolve();
//         }).catch(error => {
//             console.log(error);
//             reject()
//         })
//     })
// }

// async function init() {
//     // await getServerConfig();
//     let res = await getConfig();
//     console.log(EN_FOOT_COMPANY.base_url);
    
//     EN_FOOT_COMPANY.base_url = res.data.base_url;
//     console.log(EN_FOOT_COMPANY);
// }  

// app.use(init)

app.mount('#app')
