import { axios } from "@/config";
const api = {
    list: '/Banners/list'
}

/**
 * 列表
 * @param {*} data
 */
export function list(data) {
    return axios({
        url: api.list,
        method: 'post',
        data
    })
}