import { axios } from "@/config";
const api = {
    getList: '/notice/getList',
    doRead: '/notice/doRead',
    getAlertNotice: '/notice/getAlertNotice',
    getUnreadMessageNums: '/notice/getUnreadMessageNums'
}

/**
 * 获取数据
 * @param {*} data
 */
export function getList(data) {
    return axios({
        url: api.getList,
        method: 'post',
        data
    })
}

/**
 * 已阅读
 * @param {*} data 
 * @returns 
 */
export function doRead(data) {
    return axios({
        url: api.doRead,
        method: 'post',
        data
    })
}

/**
 * 获取弹窗数据
 * @param {*} data 
 * @returns 
 */
 export function getAlertNotice(data) {
    return axios({
        url: api.getAlertNotice,
        method: 'post',
        data
    })
}

/**
 * 获取未读信息记录数
 * @param {*} data 
 * @returns 
 */
 export function getUnreadMessageNums(data) {
    return axios({
        url: api.getUnreadMessageNums,
        method: 'post',
        data
    })
}