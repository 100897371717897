<template>
    <svg class="svg-icon" :class="iconClass" aria-hidden="true">
        <use :href="'#icon-'+type"/>
    </svg>
</template>

<script>
    export default {
        name: "svg-icon",
        props:{
            iconClass: {
                type: String
            },
            type:{
                type: String,
                default: '',
                require: true
            }
        },
        setup(){
            //获取当前svg目录下的所有svg文件
            const req = require.context('@/assets/icons',false,/\.svg$/);
            //解析
            const requireAll = (requireContext) =>{
                return requireContext.keys().map(requireContext);
            }
            requireAll(req)
        }
    }
</script>
<style scoped>
.svg-icon {
  width: 1.2em;
  height: 1.2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>