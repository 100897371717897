<template>
  <div class="loadingOut">
    <!-- <img src="../../../assets/images/loading.gif" /> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
        <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
        <path fill="#FF6700" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(42.1171 20 20)">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="1s" repeatCount="indefinite"></animateTransform>
        </path>
    </svg>
    <h3>页面加载中...</h3>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "PageLoading",
  props: [""],
  setup() {
    return {};
  },
});
</script>

<style scoped>
.loadingOut {
  position: absolute; /*参照物是父容器*/
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%); /*百分比的参照物是自身*/
  text-align: center;
}
.loadingOut h3{
    font-weight: normal;
    color: #989898;
}
</style>