import { ElNotification } from "element-plus";
const { MOBILE_REGULAR, EMAIL_REGULAR } = require("../../../public/config");
import { http } from "@/config/utils/http";
import { BASE_URL } from "@/../public/config";
import { useRouter } from "vue-router";

//检测手机号
export function checkPhone(_phone) {
    let phone = _phone.trim();
    if (phone == "") {
        return { message: '请输入手机号', type: 'error' };
    }
    if (!MOBILE_REGULAR.test(phone)) {
        return { message: '请输入合法手机号', type: 'error' };
    }
    return { message: '', type: 'success' };
}

//检测邮箱地址
export function checkEmail(_email) {
    let email = _email.trim();
    if (email == "") {
        return { message: '请输入邮件地址', type: 'error' };
    }
    if (!EMAIL_REGULAR.test(email)) {
        return { message: '请输入合法邮件地址', type: 'error' };
    }
    return { message: '', type: 'success' };
}

/**
 * 通知弹出框封装
 * @param {*} title 标题
 * @param {*} msg 内容信息
 * @param {*} type 类型，值有：success（成功）、warning（警告）、info（提示）、error（错误）
 * @param {*} time 延迟关闭时间，单位：秒
 */
export function notice(title, msg, type, time = 2500) {
    ElNotification({
        title: title,
        message: msg,
        type: type,
        duration: time
    });
}

//获取当前节点上data-key的值
export function getDataSet(event, key) {
    return event.currentTarget.dataset['' + key + ''];
}

// 验证登录
export function isLogin() {
    const router = useRouter();
    const userInfo = cache("userInfo")
    if (!userInfo || !userInfo["Authorization-Token"]) {
        clearLogin()
        // 跳转
        router.push({
            path: "/user/login",
        });
        return false
    }
    return true
}

/**
 * 清除登录信息 
 */
export function clearLogin() {
    cache('userInfo', null)
}

/**
 * [cache 本地缓存]
 * 读取：cache(key)
 * 设置：cache(key,value)
 * 删除单个：cache(key,null)
 * 清除所有：cache(null)
 * @param  {[type]} key   [索引]
 * @param  {[type]} value [缓存值]
 * @param  {[type]} time  [有效期(秒)]
 */
export function cache(key, value, time) {
    if (typeof key != 'undefined' && key != null && typeof value != 'undefined' && value != null) {
        if (typeof time !== 'undefined' && time != null) {
            let expirse = Number(Math.round(new Date() / 1000)) + Number(time);
            let setdata = { value: value, expirse: expirse };
            window.localStorage.setItem(key, JSON.stringify(setdata));
        } else {
            let setdata = { value: value };
            window.localStorage.setItem(key, JSON.stringify(setdata));
        }
    } else if (typeof key !== 'undefined' && key !== null && typeof value === 'undefined') {
        window.localStorage.setItem('ie-edge', JSON.stringify(Number(Math.round(new Date() / 1000))));
        var getvalue = JSON.parse(window.localStorage.getItem(key));
        if (getvalue && getvalue != undefined && getvalue != null) {
            if (getvalue.expirse != undefined && getvalue.expirse != null && getvalue.expirse < Math.round(new Date() / 1000)) {
                window.localStorage.removeItem(key);
                return '';
            } else {
                return getvalue.value;
            }
        }
        return '';
    } else if (typeof key !== 'undefined' && key !== null && typeof value !== 'undefined' && value === null) {
        window.localStorage.removeItem(key);
        window.localStorage.removeItem('ie-edge');
    } else {
        window.localStorage.clear();
    }
}

/**
 * 设置路由选项
 * @param {*} name 路由名称
 * @param {*} query 路由参数
 * @returns 
 */
export function setRouterOption(name='', query='') {
    return {
        "name": name,
        "query": query
    };
}

/**
 * 获取请求地址
 * @returns 
 */
export function baseUrl() {
    return BASE_URL;
}

/**
 * 请求二次封装，解决缓存不是最新的bug
 * @param {string} url 请求地址
 * @param {string} method 请求方式：GET|POST 
 * @param {object} data 请求数据体 
 * @param {string|object} source 每次请求取消凭证，此方式合适单个取消请求
 * @param {object} headers 请求头
 * @param {string} tokenName 请求token名称
 * @returns 
 */
export function axios({ url, method = "post", data = {}, source = '', headers = {}, tokenName = 'Authorization-Token' }) {
    var userInfo = cache('userInfo')
    var HttpsToken = userInfo && userInfo[tokenName] ? userInfo[tokenName] : false
    headers[tokenName] = headers[tokenName] ? headers[tokenName] : HttpsToken
    return http({
        url,
        headers,
        method,
        data,
        cancelToken: source ? source.token : ""
    })
}

/**
 * 判断数据类型
 * value：数据
 */
export function judgeDataType(value) {
    console.log(value);
}

export function getCurrentDateTime() {
    let now = new Date();
    let year = now.getFullYear();
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day = now.getDate().toString().padStart(2, '0');
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

// export function svgToPng(svgText, margin, fill) {
//     console.log(svgText)
//     // convert an svg text to png using the browser
//     return new Promise(function (resolve, reject) {
//         try {
//             // can use the domUrl function from the browser
//             var domUrl = window.URL || window.webkitURL || window;
//             if (!domUrl) {
//                 throw new Error("(browser doesnt support this)")
//             }

//             // figure out the height and width from svg text
//             var match = svgText.match(/height=['|"](\d+)/m);
//             console.log(match);
//             var height = match && match[1] ? parseInt(match[1], 10) : 200;
//             match = svgText.match(/width=['|"](\d+)/m);
//             console.log(match)
//             var width = match && match[1] ? parseInt(match[1], 10) : 200;
//             margin = margin || 0;

//             // it needs a namespace
//             if (!svgText.match(/xmlns="/mi)) {
//                 svgText = svgText.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
//             }

//             // create a canvas element to pass through
//             var canvas = document.createElement("canvas");
//             canvas.width = height + margin * 2;
//             canvas.height = width + margin * 2;
//             var ctx = canvas.getContext("2d");


//             // make a blob from the svg
//             var svg = new Blob([svgText], {
//                 type: "image/svg+xml;charset=utf-8"
//             });

//             // create a dom object for that image
//             var url = domUrl.createObjectURL(svg);
//             console.log(url)

//             // create a new image to hold it the converted type
//             var img = new Image;

//             // when the image is loaded we can get it as base64 url
//             img.onload = function () {
//                 console.log('safad')
//                 // draw it to the canvas
//                 ctx.drawImage(this, margin, margin);

//                 // if it needs some styling, we need a new canvas
//                 if (fill) {
//                     var styled = document.createElement("canvas");
//                     styled.width = canvas.width;
//                     styled.height = canvas.height;
//                     var styledCtx = styled.getContext("2d");
//                     styledCtx.save();
//                     styledCtx.fillStyle = fill;
//                     styledCtx.fillRect(0, 0, canvas.width, canvas.height);
//                     styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
//                     styledCtx.restore();
//                     styledCtx.drawImage(canvas, 0, 0);
//                     canvas = styled;
//                 }
//                 // we don't need the original any more
//                 domUrl.revokeObjectURL(url);
//                 // now we can resolve the promise, passing the base64 url
//                 resolve(canvas.toDataURL());
//             };

//             // load the image
//             img.src = canvas.toDataURL();
//             console.log(img)

//         } catch (err) {
//             reject('failed to convert svg to png ' + err);
//         }
//     });
// }

// function convertToPng(src, w, h) {
//     var canvas = document.createElement('canvas');
//     var context = canvas.getContext('2d');
//     console.log(context)
//     canvas.width = w;
//     canvas.height = h;
//     console.log('34324')
//     context.drawImage(src, 0, 0);
//     console.log('sfa')
//     var png;
//     try {
//         png = canvas.toDataURL("image/png");
//         console.log(png)
//     } catch (e) {
//         if ((typeof SecurityError !== 'undefined' && e instanceof SecurityError) || e.name == "SecurityError") {
//             console.error("Rendered SVG images cannot be downloaded in this browser.");
//             return;
//         } else {
//             throw e;
//         }
//     }
//     console.log(png)
//     return png;
// }

// function isElement(obj) {
//     console.log(obj instanceof HTMLElement || obj instanceof SVGElement)
//     return obj instanceof HTMLElement || obj instanceof SVGElement;
// }

// function reEncode(data) {
//     data = encodeURIComponent(data);
//     data = data.replace(/%([0-9A-F]{2})/g, function (match, p1) {
//         var c = String.fromCharCode('0x' + p1);
//         return c === '%' ? '%25' : c;
//     });
//     return decodeURIComponent(data);
// }

function uriToBlob(uri) {
    var byteString = window.atob(uri.split(',')[1]);
    var mimeString = uri.split(',')[0].split(':')[1].split(';')[0]
    var buffer = new ArrayBuffer(byteString.length);
    var intArray = new Uint8Array(buffer);
    for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([buffer], { type: mimeString });
}

/**
 * 下载base64位图片
 * @param {base64图片内容} base64 
 * @param {文件名} name 
 */
export function downLoad(base64, name = "result.png") {
    var saveLink = document.createElement('a');
    var downloadSupported = 'download' in saveLink;
    if (downloadSupported) {
        saveLink.download = name;
        saveLink.style.display = 'none';
        document.body.appendChild(saveLink);
        try {
            var blob = uriToBlob(base64);
            var url = URL.createObjectURL(blob);
            saveLink.href = url;
            saveLink.onclick = function () {
                requestAnimationFrame(function () {
                    URL.revokeObjectURL(url);
                })
            };
        } catch (e) {
            console.log(e);
            // saveLink.href = uri;
        }
        saveLink.click();
        document.body.removeChild(saveLink);
    }
}

/**
 * 获取当前时间
 * @param {分隔符} seperator 
 * @returns 
 */
export function getDate(seperator = '') {
    let dateArr = [];
    let date = new Date();
    dateArr.push(date.getFullYear());//年
    dateArr.push(fillDateTime(date.getMonth() + 1));//月
    dateArr.push(fillDateTime(date.getDate()));//日
    dateArr.push(fillDateTime(date.getHours()));//时
    dateArr.push(fillDateTime(date.getMinutes()));//分
    dateArr.push(fillDateTime(date.getSeconds()));//秒
    return dateArr.join(seperator);
}

/**
 * 将时间不满二位数的，前面加0
 * @param {填充的值} number 
 * @returns 
 */
export function fillDateTime(number = 0) {
    if (number >= 0 && number <= 9) {
        number = '0' + number;
    }
    return number;
}

/**
 * 深拷贝，解决指针问题
 * @param {*} obj 
 * @returns 
 */
export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

/**
 * 拼接地址参数
 * @param {object} paramObj 参数对象
 */
export function splicingParams(paramObj) {
    let params = '';
    if (JSON.stringify(paramObj) != '{}') {
        let count = 1;
        for (let index in paramObj) {
            if (count == 1) {
                params += '?' + index + '=' + paramObj[index];
            } else {
                params += '&' + index + '=' + paramObj[index];
            }
            count++;
        }
    }
    console.log(params);
    return params;
}

/**
 * 获取url参数
 * @param {string} url 地址
 * @returns 
 */
export function getUrlParams(url) {
    // let urlStr = url.split('?')[1];
    // const urlSearchParams = new URLSearchParams(urlStr);
    // const result = Object.fromEntries(urlSearchParams.entries());
    // return result;

    // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
    let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
    let result = {};
    url.replace(pattern, ($, $1, $2) => {
        result[$1] = $2;
    })
    return result;
}

//处理面包屑路径
export function handlePath(pathArr) {
    let tempArr = [];
    if (pathArr.length > 0) {
        for (let item of pathArr) {
            if (item != '') { //去除/
                tempArr.push(item);
            }
        }
    }
    return tempArr;
}

/**
 * 
 * @param {*} dateSeparate 日期之间的分隔符，默认-
 * @param {*} dateAndTimeSeparate 日期与时间之间的分隔符，默认空格
 * @param {*} timeSeparate 时间之间的分隔符，默认:
 * @returns 
 */
export function getNowDate(dateSeparate='-', dateAndTimeSeparate=' ', timeSeparate=':'){
    const now = new Date();

    const year = now.getFullYear();
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);
    const seconds = ('0' + now.getSeconds()).slice(-2);

    return year + dateSeparate + month + dateSeparate + day + dateAndTimeSeparate + hours + timeSeparate + minutes + timeSeparate + seconds;
}

// export function downLoad(el, name) {
//     console.log(el)
//     if (!isElement(el)) {
//         throw new Error('an HTMLElement or SVGElement is required; got ' + el);
//     }
//     console.log(el)
//     if (!name) {
//         console.error("文件名为空!");
//         return;
//     }
//     var xmlns = "http://www.w3.org/2000/xmlns/";
//     var clone = el.cloneNode(true);
//     clone.setAttribute("version", "1.1");
//     if (!clone.getAttribute('xmlns')) {
//         clone.setAttributeNS(xmlns, "xmlns", "http://www.w3.org/2000/svg");
//     }
//     if (!clone.getAttribute('xmlns:xlink')) {
//         clone.setAttributeNS(xmlns, "xmlns:xlink", "http://www.w3.org/1999/xlink");
//     }
//     var svg = clone.outerHTML;
//     var uri = 'data:image/svg+xml;base64,' + window.btoa(reEncode(doctype + svg));
//     console.log(uri);
//     var image = new Image();
//     image.onload = function () {
//         var png = convertToPng(image, image.width, image.height);
//         var saveLink = document.createElement('a');
//         var downloadSupported = 'download' in saveLink;
//         if (downloadSupported) {
//             saveLink.download = name + ".png";
//             saveLink.style.display = 'none';
//             document.body.appendChild(saveLink);
//             try {
//                 var blob = uriToBlob(png);
//                 var url = URL.createObjectURL(blob);
//                 saveLink.href = url;
//                 saveLink.onclick = function () {
//                     requestAnimationFrame(function () {
//                         URL.revokeObjectURL(url);
//                     })
//                 };
//             } catch (e) {
//                 saveLink.href = uri;
//             }
//             saveLink.click();
//             document.body.removeChild(saveLink);
//         }
//     };
//     image.src = uri;
// }