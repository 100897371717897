<template>
  <Header menuType="index" />
  <Silde />
  <div class="home">
    <div class="solutionOut">
      <div class="title">专注于生物信息软硬一体算力解决方案</div>
      <div>高算力场景提供一站式云端计算分析平台，优化应用效率，智能调度云端算力资源，屏蔽底层IT技术细节</div>
      <div class="mgtop20">一站式调优解决方案，根据生命科学行业应用优化标准</div>
      <div class="list">
        <div class="item">
          <img src="~@/assets/images/home/solution_icon.png" />
          <div class="title">简单易用，快速上手</div>
          <div>可视化操作界面取代命令行</div>
          <div>一键开启作业分析</div>
          <div>零生信基础也可以快速上手</div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/solution_icon1.png" />
          <div class="title">数据可视化</div>
          <div>无需学习编程语言</div>
          <div>科研工作可以按照自己的实际需求</div>
          <div>自主进行数据分析</div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/solution_icon2.png" />
          <div class="title">资源无限扩展</div>
          <div>计算资源弹性拓展</div>
          <div>速调配计算资源</div>
          <div>并采用最优配置进行计算</div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/solution_icon3.png" />
          <div class="title">全面安全保障</div>
          <div>多层次安全防护</div>
          <div>数据多重备份</div>
          <div>保障数据的完整性</div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/solution_icon4.png" />
          <div class="title">降低运维成本</div>
          <div>可视化操作界面取代命令行</div>
          <div>一键开启作业分析</div>
          <div>零生信基础也可以快速上手</div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/solution_icon5.png" />
          <div class="title">智能化调度，提升效率</div>
          <div>适配计算资源和存储资源</div>
          <div>达到物尽其用的效果</div>
        </div>
      </div>
    </div>
    <div class="toolsOut">
      <div class="title">云工具零代码操作 不懂生物信息学也能用</div>
      <div class="list">
        <div class="item">
          <img src="~@/assets/images/home/tools_icon.png" />
          <div class="title">功能分析</div>
          <div class="content">GO富集分析，KEGG富集分析，富集差异气泡图，Reactome富集分析</div>
          <div class="more greenColor"><router-link :to="{path:'/tools'}">查看更多+</router-link></div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/tools_icon1.png" />
          <div class="title">动态交互</div>
          <div class="content">动态火山图，动态热图，动态堆叠图，动态PCA，动态PCoA</div>
          <div class="more blueColor"><router-link :to="{path:'/tools'}">查看更多+</router-link></div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/tools_icon2.png" />
          <div class="title">聚类分析</div>
          <div class="content">PCA，分层聚类分析，CCA/RDA，NMDS，PCoA</div>
          <div class="more greenColor"><router-link :to="{path:'/tools'}">查看更多+</router-link></div>
        </div>
        <div class="item">
          <img src="~@/assets/images/home/tools_icon3.png" />
          <div class="title">差异分析</div>
          <div class="content">（O）PLS-DA，ROC，配对edgeR/DESeq2差异分析，O2PLS分析</div>
          <div class="more blueColor"><router-link :to="{path:'/tools'}">查看更多+</router-link></div>
        </div>
      </div>
    </div>
    <div class="regeditOut">
      <div>体验云分析系统，让生物信息更简单</div>
    </div>
    <div class="experienceOut">
      <div class="title">先进的人工智能技术，打造高智能分析交互体验</div>
      <img src="~@/assets/images/home/experience_icon.png" />
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import Header from "../layouts/HeaderLayer";
import Silde from "../components/SlidingBanner";
import Footer from "../layouts/FooterLayer";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    Silde,
    Footer
  },
  setup() {
    return {
      
    };
  },
});
</script>

<style lang="less">
*{
  font-family: "Helvetica Neue",Helvetica,'Microsoft Yahei','Hiragino Sans GB','WenQuanYi Micro Hei',Tahoma,Arial,sans-serif;
  // font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: #fff;
}
.mgtop20{
  margin-top:20px;
}
.blueColor{
  background-color: #11B0F0;
}
.greenColor{
  background-color: #33CC99;
}
.home {
  width: 100%;
  margin: 0 auto;
}
.home .solutionOut{
  padding:80px 0;
  background: url('@/assets/images/home/solution_bg.jpg') no-repeat; 
  background-size:100% 100%;
  text-align: center;    
  line-height: 25px;
}
.home .solutionOut>.title{
  font-size: 1.8rem;
  margin-bottom: 40px;
}
.home .solutionOut .list{
  width:1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-content: flex-start;
  flex-flow: row wrap;
}
.home .solutionOut .list .item{
  flex:  0 0 33.33%;/*每行排三个元素*/
  margin-top: 50px;
}
.home .solutionOut .list .item img{
  width:120px;
  height: 120px;
}
.home .solutionOut .list .item .title{
  font-size: 1.5rem;
  margin: 10px 0;
}
.home .toolsOut{
  padding: 80px 0;
  text-align: center;
}
.home .toolsOut>.title{
  font-size: 1.8rem;
  margin-bottom: 40px;
}
.home .toolsOut .list{
  width:1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.home .toolsOut .list .item{
  background-color: #fff;
  padding: 80px 50px;
  width:25%;
  margin: 10px;
}
.home .toolsOut .list .item .title{
  font-size: 1.5rem;
  margin: 10px 0;
  margin: 30px 0 20px;
}
.home .toolsOut .list .item .content{
  height: 100px;
  line-height: 25px;
}
.home .toolsOut .list .item .more{
  border-radius: 5px;
  padding: 10px;
  color:#fff;
  width: 80px;
  margin: 0 auto;
  cursor: pointer;
}
.home .regeditOut{
  background: url('@/assets/images/home/regedit.jpg') no-repeat; 
  background-size:100% 100%;
  height: 350px;
  color:#fff;
}
.home .regeditOut div{
  font-size: 1.8rem;
  text-align: center;
  line-height: 350px;
}
.home .experienceOut{
  padding: 50px 0;
  background: url('@/assets/images/home/experience_bg.jpg') no-repeat; 
  background-size: cover;
  text-align: center;
}
.home .experienceOut>.title{
  font-size: 1.8rem;
  margin-bottom: 60px;
}
/*
  问题：此处有个坑，如果为<style scoped>，此处的样式不起效，如果样式冲突，如何解决？
 */
// .el-popper.is-dark, .el-popper.is-light{
//   max-width:230px !important;
// }
//以上方式可以改用以下方式进行解决，在<el-tooltip>节点内添加popper-class="my-tooltip"属性
.my-tooltip {
  //背景颜色
  max-width: 236px !important; //注意：此处最好加上!important，否则会被全局类的样式覆盖
  background-color: #0682c6 !important;
  border: 1px solid #0682c6 !important;
}
.my-tooltip.el-popper.is-dark .el-popper__arrow::before {
  //三角型箭头样式
  background: #0682c6 !important;
  border: 1px solid #0682c6 !important;
}
//滑动块样式
.my-slider-tooltip {
  //背景颜色
  max-width: 236px !important; //注意：此处最好加上!important，否则会被全局类的样式覆盖
  background-color: #058B00 !important;
  border: 1px solid #058B00 !important;
}
.my-slider-tooltip.el-popper.is-dark .el-popper__arrow::before {
  //三角型箭头样式
  background: #058B00 !important;
  border: 1px solid #058B00 !important;
}
//以上方式可以改用以下方式进行解决，在<el-tooltip>节点内添加popper-class="my-file-tooltip"属性
//选择文件样式
.my-file-tooltip {
  //背景颜色
  width: 100px !important;
  max-width: 230px !important; //注意：此处最好加上!important，否则会被全局类的样式覆盖
  background-color: #fff !important;
  border: 1px solid #0682c6 !important;
  color:#0682c6 !important;
  padding: 5px 10px !important;
}
.my-file-tooltip.el-popper.is-dark .el-popper__arrow::before {
  //三角型箭头样式
  background: #fff !important;
  border-bottom: 1px solid #0682c6 !important;//左侧边框颜色
  border-right: 1px solid #0682c6 !important;//右侧边框颜色
}
</style>