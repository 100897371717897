<template>
  <div class="out">
    <div class="headerOut">
      <div class="left">
        <img src="../assets/images/logo.png" @click="goHome" />
        <img src="../assets/images/subLogo.png" @click="goCloud" />
        <div v-if="isLogin">用户中心</div>
      </div>
      <ul class="menu">
        <li
          class="hoverClass"
          @click="handleSelect"
          data-type="index"
          :class="{ active: current == 'index' }"
        >
          首页
        </li>
        <li
          class="hoverClass"
          @click="handleSelect"
          data-type="tools"
          :class="{ active: current == 'tools' }"
        >
          云分析
        </li>
        <li
          class="hoverClass"
          @click="handleSelect"
          data-type="videos"
          :class="{ active: current == 'videos' }"
        >
          培训视频
        </li>
        <!-- <li
          class="hoverClass"
          @click="handleSelect"
          data-type="aboutUs"
          :class="{ active: current == 'aboutUs' }"
        >
          关于我们
        </li>
        <li
          class="hoverClass"
          @click="handleSelect"
          data-type="news"
          :class="{ active: current == 'news' }"
        >
          新闻中心
        </li> -->
        <!-- <li
          class="hoverClass"
          @click="handleSelect"
          data-type="contactUs"
          :class="{ active: current == 'contactUs' }"
        >
          联系我们
        </li> -->
        <li
          v-if="!isLogin"
          @click="handleSelect"
          data-type="login"
          class="hoverClass"
          :class="{ active: current == 'login' }"
        >
          登录
        </li>
        <li
          v-if="!isLogin"
          @click="handleSelect"
          data-type="regedit"
          class="hoverClass"
          :class="{ active: current == 'regedit' }"
        >
          注册
        </li>
        <!-- <li
          @click="handleSelect"
          data-type="test"
          class="hoverClass"
          :class="{ active: current == 'test' }"
        >
          测试
        </li> -->
        <!-- <li
          @click="handleSelect"
          data-type="test1"
          class="hoverClass"
          :class="{ active: current == 'test1' }"
        >
          测试1
        </li> -->
        <li
          v-if="isLogin"
          class="info"
          data-type="login"
          :class="{ active: current == 'login' }"
          @mouseenter="showMyPop"
          @mouseleave="hideMyPop"
          @click="toOverview"
        >
          <img
            :src="
              avatar.length > 0
                ? avatar
                : require('../assets/images/noavatar_middle.gif')
            "
          />
          <div>{{ username }}</div>
          <el-icon class="down">
            <CaretTop />
          </el-icon>
          <transition name="fade">
            <div class="headPopOut" v-show="isShowMyPop">
              <div class="menu">
                <!-- 注意：此处的父节点<li class="info">有点击事件，为了在点击router-link不让事件冒泡到父节点，所以此处使用了event.stopPropagation() -->
                <router-link to="/user/overview" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="overview" />
                    信息总览
                  </div>
                </router-link>
                <router-link to="/user/info" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="myinfo" />
                    个人信息
                  </div>
                </router-link>
                <router-link to="/user/projects" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="myprojects" />
                    我的项目
                  </div>
                </router-link>
                <router-link to="/user/datas" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="mydatas" />
                    我的数据
                  </div>
                </router-link>
                <router-link to="/user/collection" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="favorites" />
                    我的收藏
                  </div>
                </router-link>
                <router-link to="/user/notice" onclick="event.stopPropagation();">
                  <div>
                    <svg-icon type="notice" />
                    消息中心
                  </div>
                </router-link>
                <!-- <div>
                  <svg-icon type="invoice" />
                  我的发票
                </div> -->
              </div>
              <div class="exit" @click="exit">
                <svg-icon type="exit" />
                退出
              </div>
            </div>
          </transition>
        </li>
        <li class="gotoMessage" v-if="isLogin" @click="toMessageCenter">
          <!-- <el-tooltip
            class="box-item"
            effect="dark"
            content="消息"
            placement="right"
            :hide-after="0"
            popper-class="my-tooltip"
          >  -->
            <div class="message">
              <svg-icon type="bell" />
              <div class="nums" v-if="unreadMessageNums != 0 && !Number.isNaN(unreadMessageNums)">{{unreadMessageNums}}</div>
            </div>
          <!-- </el-tooltip> -->
        </li>
        <li id="lng"><span class="active" @click="changeLang('cn')">中文</span>｜<span @click="changeLang('en')">English</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { CaretTop } from "@element-plus/icons-vue";
import { cache, clearLogin } from "@/config";
import { logout } from "@/api/user/index";
import { ElMessageBox } from "element-plus";
import store from "@/store";

export default defineComponent({
  name: "HeaderLayer",
  props: {
    menuType: {
      //注意：此处必须定义，否则setup的props.menuType打印时为undefined
      type: String,
      default: "",
    },
  },
  components: {
    CaretTop,
  },
  setup(props) {
    // console.log(props.menuType);
    const router = useRouter();
    let language = cache('language');
    
    // console.log(store.state.userInfo);

    let userInfo = cache("userInfo");
    const username = userInfo.UserName;
    const avatar = userInfo.Avatar;

    let current = ref(props.menuType);
    let isShowMyPop = ref(false); //是否显示个人中心信息弹窗，默认不显示。

    // let userInfo = cache("userInfo");
    let isLogin = ref(false); //是否已经登录，用于是否显示个人信息，默认为未登录
    //如果已经登录的，显示用户信息
    if (userInfo) {
      isLogin.value = true;
    }

    let state =  reactive({
      unreadMessageNums: 0
    })

    watch(() => store.getters.getUnreadMessageNums, //监视store的参数
      (newValue/*, oldValue*/) => {
        state.unreadMessageNums = newValue;
        // console.log(newValue, oldValue)
      },
      { immediate: true }
    );

    const toMessageCenter = () =>{
      router.push({
        path: "/user/notice",
      });
    }
    
    onMounted(async () => {
      store.commit('countUnreadMessageNums');
      // store.dispatch('countUnreadNoticeNumsAsyn');
    });

    const changeLang = (lang) => {
      store.commit('setLang', lang);
      language = cache('language');

      //匹配以En结尾的字符串，如：ToolsViewEn
      let reg = new RegExp("\\w*En$", 'ig');
      let currentRouteName = router.currentRoute._value.name;
      let matchResult = reg.test(currentRouteName);
      if(language == '' || language == 'cn'){
        if(matchResult){ //如果是中文状态的，但当前路由名称又是英文的，将英文地址改成中文地址
          router.push({
            name: currentRouteName.replace('En', '')
          });
        }
      }else{
        if(!matchResult){ //如果是英文状态的，但当前路由名称又是中文的，将中文地址改成英文地址
          router.push({
            name: currentRouteName + 'En'
          });
        }
      }
    }

    const exit = (e) => {
      e.stopPropagation(); //阻止事件冒泡
      e.preventDefault(); //阻止元素的默认行为
      ElMessageBox.confirm("您确定退出吗？", "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: 'btnConfirm',
        cancelButtonText: "取消"
      })
        .then(async () => {
          await logout({lang: language}); //先清除服务器上的缓存
          await clearLogin(); //再清除本地浏览器缓存
          isLogin.value = false;
          goHome();
        })
        .catch(() => {});
    };

    const handleSelect = (event) => {
      let type = event.currentTarget.dataset.type;
      switch (type) {
        case "index":
          // 跳转
          router.push({
            path: "/",
          });
          current = "index";
          break;
        case "tools":
          router.push({
            path: "/tools",
          });
          current = "tools";
          break;
        case "videos":
          router.push({
            path: "/videos",
          });
          current = "videos";
          break;
        case "aboutUs":
          router.push({
            path: "/about",
          });
          current = "aboutUs";
          break;
        case "news":
          router.push({
            path: "/news",
          });
          current = "news";
          break;
        case "contactUs":
          router.push({
            path: "/contactUs",
          });
          current = "contactUs";
          break;
        case "login":
          router.push({
            path: "/user/login",
          });
          current = "login";
          break;
        case "regedit":
          router.push({
            path: "/user/regedit",
          });
          current = "regedit";
          break;
        case "test":
          router.push({
            path: "/test",
          });
          current = "test";
          break;
        case "test1":
          router.push({
            path: "/test1",
          });
          current = "test1";
          break;
        default:
          break;
      }
    };

    const goHome = () => {
      let path = router.currentRoute.path;
      if (path !== "/") {
        router.push({
          path: "/",
        });
      }
    };

    const goCloud = () => {
      let path = router.currentRoute.path;
      if (path !== "/") {
        router.push({
          path: "/tools",
        });
      }
    }

    const showMyPop = () => {
      //显示个人中心
      isShowMyPop.value = true;
    };

    const hideMyPop = () => {
      //隐藏个人中心
      isShowMyPop.value = false;
    };

    const toOverview = (event) => { //信息总览页面
      event.preventDefault();
      event.stopPropagation();
      router.push({
        name: "MyOverview",
      });
    }

    return {
      goHome,
      goCloud,
      current,
      handleSelect,
      isShowMyPop,
      showMyPop,
      hideMyPop,
      isLogin,
      exit,
      username,
      avatar,
      ...toRefs(state),
      toMessageCenter,
      changeLang,
      toOverview
    };
  }
});
</script>

<style lang="less" scoped>
* {
  font-size: 1.12rem;
}
a:visited {
  color: #505050;
}
a {
  color: #505050;
  text-decoration: none;
}
.out {
  overflow: unset !important;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  height: 55px;
}
.left div {
  font-size: 1.5rem;
  margin-left: 10px;
  border-left: 1px solid #d0d1d1;
  padding-left: 10px;
  color: #000;
  height: 55px;
  line-height: 55px;
}
.left img {
  cursor: pointer;
}
ul {
  list-style-type: none;
  margin: 0;
  color: #1f1f1f;
}
li {
  padding: 0 20px;
  position: relative;
}
.menu {
  display: flex;
  height: 75px;
  line-height: 75px;
}
.menu li.hoverClass::after {
  /*鼠标移动菜单栏动画*/
  content: "";
  height: 3px;
  overflow: hidden;
  display: block;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #0682c6;
  transform: scaleX(0);
  transition: all 0.5s;
}
.menu li.hoverClass:hover::after {
  /*鼠标移动菜单栏动画*/
  transform: scaleX(1);
}
.menu li.active {
  color: #0682c6;
}
.menu li.active::after {
  color: #0682c6;
  /* border-bottom: 3px solid #0682c6; */
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #0682c6;
  height: 3px;
  transform: none;
}
.menu li.hoverClass:hover {
  color: #0682c6;
  cursor: pointer;
  /* border-bottom: 3px solid #0682C6; */
}
.headerOut {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1200px;
  max-width: 98%;
  background-color: #fff;
  margin: 0 auto;
  align-items: flex-end;
}
.headerOut .left img {
  width: 136px;
  height: 36px;
  margin-top: 3px;
}
.headerOut .left img:first-child {
  width: 150px;
  height: 44px;
  margin-right: 10px;
  margin-top: 0;
}
.headerOut .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.headerOut .info img {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  margin-right: 5px;
}
.headerOut .info .headPopOut {
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 200px;
  color: #505050;
  z-index: 9999;
}
.headerOut .info .headPopOut .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.headerOut .info .headPopOut .header:hover {
  cursor: default;
}
.headerOut .info .headPopOut .header img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.headerOut .info .headPopOut .menu {
  display: block;
  text-indent: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  line-height: 35px;
}
.headerOut .info .headPopOut .menu div:hover {
  color: #fff;
  background-color: #0682c6;
}
.headerOut .info .headPopOut .exit {
  text-indent: 25px;
  padding: 5px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 35px;
}
.headerOut .headPopOut .svg-icon {
  margin-right: 10px;
}
.el-loading-mask {
  z-index: 5 !important;
}
.svg-icon {
  width:1.28rem !important;
  height: 1.28rem !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
li.info:hover { //鼠标移上箭头向下
  .down {
    transform: rotate(-180deg);
  }
}
li.gotoMessage{
  cursor: pointer;
}
li.gotoMessage .message {
  position: relative;
  color: #504f4f;
}
li.gotoMessage .message .svg-icon {
  font-size: 1.35rem;
}
li.gotoMessage .message .nums {
  border-radius: 50%;
  background-color: red;
  color: #fff;
  position: absolute;
  right: -15px;
  top: 10px;
  line-height: 19px;
  width: 19px;
  height: 19px;
  text-align: center;
  font-size: 0.9rem;
}
li#lng span{
  cursor: pointer;
}
li#lng span.active{
  color: #058B00
}
/* .fade-enter-active, .fade-leave-active {
  animation: fadenum 1s 1;
}
@keyframes fadenum{
  0%{opacity: 1;}
  100%{opacity: 0;}
} */

/* .fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}
.fade-enter {
  transform: translateY(-100%);
}
.fade-enter-to {
  transform: translateY(0%);
}
.fade-leave {
  transform: translateY(0);
}
.fade-leave-to {
  transform: translateY(-100%);
} */
</style>

<style>
.el-message-box__btns .btnConfirm{
  background-color: #0682c6 !important;
  border: 1px solid #0682c6 !important;
}
.el-message-box__header{
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.el-message-box__headerbtn{
  top: 12px;
}
</style>